<template>
  <div id="dashboard">
    <v-container fluid align-center>
      <v-row dense>
        <v-col cols="12">
          <v-card color="transparent" class="ma-0 pa-0">
            <v-img
              :src="'images/fondos/cinta2.png'"
              class="white--text align-end"
            >
            </v-img>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="orange" class="white--text" @click="siguiente()">Comenzar
                <v-icon class="mx-5">
                  mdi-arrow-right-drop-circle
                </v-icon></v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      };
  },
  methods: {
    siguiente(){
      this.$router.push("/myself");
    }
  },
  created() {},
};
</script>
